export const environment = {
  production: true,
  isLocal: false,
  auth: {
    serviceUrl: '#{authUrl}#',
    serviceIdentityClientId: '#{authClientId}#',
    serviceIdentityClientSecret: '#{authClientSecret}#',
    serviceRedirectUrlPath: 'signin-oidc',
    serviceSilentRedirectUrlPath: 'silent-renew'
  },
  appInsights: {
    instrumentationKey: '#{instrumentationKey}#'
  },
  eventServiceUrl: '#{eventServiceUrl}#',
  eventServiceConnectionRetryCount: 3,
  isportCoreApiUrl: '#{isportCoreApiUrl}#',
  chatbotDataKey: '#{chatbotDataKey}#'
};
