import { Component, OnInit } from '@angular/core';
import { ConfigurationService, TenantSettingsService, UserService } from '@core/services';

@Component({
  selector: 'guidelines-panel',
  templateUrl: './guidelines-panel.component.html',
  styleUrls: ['./guidelines-panel.component.scss']
})
export class GuidelinesPanelComponent implements OnInit {
  userProfileAvailableFields!: string[];
  workshopGuidelinesUrl!: string;
  clearingGuidelinesWkmUrl!: string;
  clearingGuidelinesWarrantyUrl!: string;
  serviceAdvisorNumberFormUrl!: string;
  isWkmDealer!: boolean;

  constructor(
    private _configurationService: ConfigurationService,
    private _tenantSettingsService: TenantSettingsService,
    private _userService: UserService
  ) {}

  ngOnInit() {
    this.userProfileAvailableFields = this._tenantSettingsService.userProfileAvailableFields.split('|');
    this.workshopGuidelinesUrl = this._configurationService.workshopGuidelinesUrlForServiceAndMaintenance.trim();
    this.clearingGuidelinesWkmUrl = this._configurationService.workshopGuidelinesUrlForMotorInsurance.trim();
    this.clearingGuidelinesWarrantyUrl = this._configurationService.workshopGuidelinesUrlForWarranty.trim();
    this.serviceAdvisorNumberFormUrl = this._configurationService.serviceAdvisorNumberFormUrl.trim();
    this.isWkmDealer = this._userService.isWkmDealer;
  }
}
