/*
 * The reason of doubling names in format enum: string is to make
 * string enums. Enums are just names used in our application and
 * they can be changed if we need easier names or something similar.
 * However, strings are actual keys that are used to load data from
 * backend endpoint and they should be changed only if backend is
 * changed.
 */
export enum ConfigurationKeys {
  CreditNoteInformationUrl = 'CreditNoteInformationUrls',
  ShowDefaultServiceAdvisor = 'ShowDefaultServiceAdvisor',
  DisplayCreditNoteInformationButton = 'DisplayCreditNoteInformationButton',
  InboxPollingInterval = 'InboxPollingInterval',
  AnnouncementPollingIntervalInSeconds = 'AnnouncementPollingIntervalInSeconds',
  SearchParameterEventDateIsPrefilled = 'SearchParameterEventDateIsPrefilled',
  TireClientCacheAbsoluteExpiration = 'TireClientCacheAbsoluteExpiration',
  ValidationConfigurationCacheAbsoluteExpiration = 'ValidationConfigurationCacheAbsoluteExpiration',
  IsMileageMandatoryInSearch = 'IsMileageMandatoryInSearch',
  ShowInsuranceReferenceField = 'ShowInsuranceReferenceField',
  ShowAllRequestVersionNotesServiceAndMaintenance = 'ShowAllRequestVersionNotesServiceAndMaintenance',
  ShowAllRequestVersionNotesInsuranceRepair = 'ShowAllRequestVersionNotesInsuranceRepair',
  ShowAllRequestVersionNotesTire = 'ShowAllRequestVersionNotesTire',
  ShowAllRequestVersionNotesWarranty = 'ShowAllRequestVersionNotesWarranty',
  ShowAllRequestVersionNotesMobility = 'ShowAllRequestVersionNotesMobility',
  ShowAllRequestVersionNotesMotorInsurance = 'ShowAllRequestVersionNotesMotorInsurance',
  ShowContractInformationInTireRequest = 'ShowContractInformationInTireRequest',
  ShowContractInformationInEditQuestionnairesForProductTypes = 'ShowContractInformationInEditQuestionnairesForProductTypes',
  ShouldPrintButtonBeVisible = 'ShouldPrintButtonBeVisible',
  ConfigurationKeyCacheAbsoluteExpiration = 'ConfigurationKeyCacheAbsoluteExpiration',
  UseCheckForDoubleInputsWithMobility = 'UseCheckForDoubleInputsWithMobility',
  UseCheckForDoubleRentalReasons = 'UseCheckForDoubleRentalReasons',
  DataPrivacyPolicyUrl = 'DataPrivacyPolicyUrl',
  DealerDamageUrl = 'DealerDamageUrl',
  InfocenterUrl = 'InfocenterUrl',
  LegalNoticeUrl = 'LegalNoticeUrl',
  UserGuideUrl = 'UserGuideUrl',
  IsActivityTypesSortingAscending = 'IsActivityTypesSortingAscending',
  IsActivityTypeClassesSortingAscending = 'IsActivityTypeClassesSortingAscending',
  IsRentalReasonsSortingAscending = 'IsRentalReasonsSortingAscending',
  IsDamageTypesSortingAscending = 'IsDamageTypesSortingAscending',
  EnableOfferManagement = 'EnableOfferManagement',
  EnableMileageUpdate = 'EnableMileageUpdate',
  ShouldSecondaryEmailAddressVisible = 'ShouldSecondaryEmailAddressVisible',
  ShowSecondaryMailAddressInCallMeBack = 'ShowSecondaryMailAddressInCallMeBack',
  Credi2Url = 'Credi2Url',
  MobilityAllowedForProductType = 'MobilityAllowedForProductType',
  EnablePerformanceLogging = 'EnablePerformanceLogging',
  ShowOfferPanelButtonOnNonSuccess = 'ShowOfferPanelButtonOnNonSuccess',
  ChatbotExcludedBusinessPartnerIds = 'ChatbotExcludedBusinessPartnerIds',
  SendingDeniedDealers = 'SendingDeniedDealers',
  AutomotiveExpertOfficeListUrl = 'AutomotiveExpertOfficeListUrl',
  WorkshopGuidelinesUrlForServiceAndMaintenance = 'WorkshopGuidelinesUrlForServiceAndMaintenance',
  WorkshopGuidelinesUrlForMotorInsurance = 'WorkshopGuidelinesUrlForMotorInsurance',
  WorkshopGuidelinesUrlForWarranty = 'WorkshopGuidelinesUrlForWarranty',
  IsLogOnPageEnabled = 'IsLogOnPageEnabled',
  ExclusiveActivityTypeClasses = 'ExclusiveActivityTypeClasses',
  PositionDiscountValueValidation = 'PositionDiscountValueValidation',
  PositionGoodwillValueValidation = 'PositionGoodwillValueValidation',
  PositionUnitPriceValueValidation = 'PositionUnitPriceValueValidation',
  PositionNetFixedPriceValueValidation = 'PositionNetFixedPriceValueValidation',
  WorkPositionQuantityValueValidation = 'WorkPositionQuantityValueValidation',
  SparePartQuantityValueValidation = 'SparePartQuantityValueValidation',
  TireServicePositionQuantityValueValidation = 'TireServicePositionQuantityValueValidation',
  TirePositionQuantityValueValidation = 'TirePositionQuantityValueValidation',
  MobilityPositionQuantityValueValidation = 'MobilityPositionQuantityValueValidation',
  TireTreadDepthValueValidation = 'TireTreadDepthValueValidation',
  UserNoteLengthValidation = 'UserNoteLengthValidation',
  ActualMileageValueValidation = 'ActualMileageValueValidation',
  WorkshopOrderNumberLengthValidation = 'WorkshopOrderNumberLengthValidation',
  ContractNumberLengthValidation = 'ContractNumberLengthValidation',
  InsuranceReferenceValueValidation = 'InsuranceReferenceValueValidation',
  MobilitySparePartPositionQuantityValueValidation = 'MobilitySparePartPositionQuantityValueValidation',
  DeductibleQuantityLengthValidation = 'DeductibleQuantityLengthValidation',
  IntegerUnits = 'IntegerUnits',
  WarrantyPositionNetFixedPriceValueValidation = 'WarrantyPositionNetFixedPriceValueValidation',
  WarrantySparePartPositionNetFixedPriceValueValidation = 'WarrantySparePartPositionNetFixedPriceValueValidation',
  WarrantyDiagnosticPositionNetFixedPriceValueValidation = 'WarrantyDiagnosticPositionNetFixedPriceValueValidation',
  PositionMaterialGoodwillValueValidation = 'PositionMaterialGoodwillValueValidation',
  Culture = 'Culture',
  NotMappedDmsActivitiesPercentagePriceLimit = 'NotMappedDmsActivitiesPercentagePriceLimit',
  NotMappedDmsActivityPriceLimit = 'NotMappedDmsActivityPriceLimit',
  NotMappedDmsActivitiesPriceLimit = 'NotMappedDmsActivitiesPriceLimit',
  ServiceAdvisorNumberFormUrl = 'ServiceAdvisorNumberFormUrl'
}
